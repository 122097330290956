import { Formik } from "formik";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Button, ButtonLink } from "@components/atoms";
import { commonMessages } from "@temp/intl";
import { TextField } from "../TextField";
import * as S from "./styles";
export var AccountingUpdateForm = function AccountingUpdateForm(_ref) {
  var handleSubmit = _ref.handleSubmit,
      hide = _ref.hide,
      initialValues = _ref.initialValues;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: initialValues,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      handleSubmit({
        euVatNumber: values.euVatNumber,
        accountingEmail: values.accountingEmail
      });
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        isSubmitting = _ref3.isSubmitting,
        isValid = _ref3.isValid;
    return React.createElement(S.Form, {
      onSubmit: handleSubmit,
      "data-test": "AccountingUpdateForm"
    }, React.createElement(S.ContentEditOneLine, null, React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "euVatNumber",
      label: intl.formatMessage(commonMessages.euVatNumber),
      type: "text",
      value: values.euVatNumber,
      onBlur: handleBlur,
      onChange: handleChange
    })), React.createElement(S.ContentExtendInput, null, React.createElement(TextField, {
      name: "accountingEmail",
      label: intl.formatMessage(commonMessages.accountingEmail),
      type: "email",
      value: values.accountingEmail,
      onBlur: handleBlur,
      onChange: handleChange
    }))), React.createElement(S.FormButtons, null, React.createElement(ButtonLink, {
      testingContext: "cancelButton",
      type: "button",
      color: "secondary",
      onClick: hide
    }, React.createElement(FormattedMessage, commonMessages.cancel)), React.createElement(Button, {
      testingContext: "submit",
      type: "submit",
      disabled: isSubmitting || !isValid,
      size: "sm"
    }, React.createElement(FormattedMessage, commonMessages.save))));
  }));
};